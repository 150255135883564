<template>
  <div>
    <div class="d-none fixed-apply position-fixed animate__animated">
      <a-row class="apply-panel">
        <a-col :span="1"> </a-col>
        <a-col :span="getBrowser == 'pc' ? 22 : 7">
          <h3 class="title text-white text-center">
            零元预约，体验智能财务SaaS系统
          </h3>
          <a-form-model
            ref="form"
            :model="formModel"
            :rules="formRules"
            @submit="submitClick"
            @submit.native.prevent
            style="margin: 0 auto; width: 300px"
          >
            <a-form-model-item prop="customerCompany">
              <a-input
                v-model="formModel.customerCompany"
                size="large"
                placeholder="公司名称"
                :maxLength="50"
              ></a-input>
            </a-form-model-item>
            <a-form-model-item prop="customerName">
              <a-input
                v-model="formModel.customerName"
                size="large"
                placeholder="姓名"
                :maxLength="50"
              ></a-input>
            </a-form-model-item>

            <a-form-model-item prop="customerPhone">
              <a-input
                v-model="formModel.customerPhone"
                size="large"
                placeholder="手机号"
                :maxLength="11"
              ></a-input>
            </a-form-model-item>

            <a-form-model-item prop="captcha">
              <a-input
                v-model="formModel.captcha"
                placeholder="验证码"
                size="large"
                :maxLength="6"
                style="width: 300px"
              >
                <template slot="suffix">
                  <cs-countdown
                    text="发送验证码"
                    ref="CsCountdown"
                    class="cursor-pointer"
                    :validate="validateCode"
                    style="color: #00a497"
                    v-slot="{ time }"
                    >{{ time }}s</cs-countdown
                  >
                </template>
              </a-input>
            </a-form-model-item>
            <a-form-model-item>
              <a-button
                type="primary"
                class="btn-submit"
                size="large"
                block
                html-type="submit"
              >
                提交申请
              </a-button>
            </a-form-model-item>
          </a-form-model>
        </a-col>
        <a-col :span="1">
          <a-icon
            type="close-circle"
            style="
              color: white;
              font-size: 30px;
              float: right;
              margin-top: 20px;
            "
            @click="closeClick"
          />
        </a-col>
      </a-row>
    </div>

    <div
      class="apply-toast position-fixed top-50 start-50 translate-middle d-flex"
      v-if="applyResult > 0"
    >
      <template v-if="applyResult == 1">
        <a-icon
          type="check-circle"
          style="font-size: 54px; color: #00a497; margin-right: 20px"
          theme="filled"
        />
        <div>
          <h3>申请已提交</h3>
          <span>我们将尽快和您联系~</span>
        </div>
      </template>
      <template v-else>
        <a-icon
          type="info-circle"
          style="font-size: 54px; color: #feae39; margin-right: 20px"
          theme="filled"
        />
        <div>
          <h3>您的申请已受理</h3>
          <span>24小时内请勿重复提交~</span>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      formModel: {
        customerCompany: "",
        customerName: "",
        customerPhone: "",
        captcha: "",
      },
      formRules: {
        customerName: [
          {
            validator: (rule, value, callback) => {
              if (!value) {
                return callback(new Error("请输入姓名"));
              }
              callback();
            },
            trigger: "submit",
          },
        ],
        customerPhone: [
          {
            validator: (rule, value, callback) => {
              if (!value) {
                return callback(new Error("请输入手机号码"));
              }
              if (!this.api.validate.isPhoneNumber(value)) {
                return callback(new Error("请输入正确的手机号码"));
              }
              callback();
            },
            trigger: "submit",
          },
        ],
        captcha: [
          {
            validator: (rule, value, callback) => {
              if (!value || value.length != 6) {
                return callback(new Error("请输入6位验证码"));
              }
              callback();
            },
            trigger: "submit",
          },
        ],
      },
      applyResult: 0,
    };
  },
  methods: {
    // 获取验证码
    async validateCode() {
      const { customerPhone } = this.formModel;
      if (!customerPhone || !this.api.validate.isPhoneNumber(customerPhone)) {
        this.$message.error("请输入正确的手机号");
        throw new Error("请输入正确的手机号");
      }
      const res = await this.api.service.fpc_oapi_customer_getCaptcha(
        {},
        { customerPhone }
      );
      if (res.code == "20801") {
        return this.showApplyMsg(2);
      }
      await this.api.toast(res, 0);
    },
    submitClick() {
      this.$refs.form.validate(async (isValid) => {
        if (isValid) {
          const res = await this.api.service.fpc_oapi_customer_submit(
            this.formModel,
            {}
          );
          if (res.code == "20801") {
            return this.showApplyMsg(2);
          }
          await this.api.toast(res, 0);
          this.showApplyMsg(1);
        }
      });
    },
    // 重置数据
    resetData() {
      this.formModel.customerCompany = "";
      this.formModel.customerName = "";
      this.formModel.customerPhone = "";
      this.formModel.captcha = "";
    },
    closeClick() {
      this.resetData();
      this.$refs.form.clearValidate();
      const fixedApply = this.$el.querySelector(".fixed-apply");
      fixedApply.classList.add("animate__fadeOutRightBig");
    },
    openClick() {
      const fixedApply = this.$el.querySelector(".fixed-apply");
      fixedApply.classList.remove("d-none", "animate__fadeOutRightBig");
      fixedApply.classList.add("animate__fadeInRightBig");
    },
    showApplyMsg(result) {
      this.applyResult = result;
      this.resetData();
      setTimeout(() => {
        this.applyResult = -1;
      }, 2000);
    },
  },
};
</script>
<style lang="less" scoped>
.fixed-apply {
  left: 0;
  z-index: 1000;
  bottom: 0;
  width: 100%;
  background: url(../../../public/img/apply_bg.jpg) no-repeat center center;
  background-size: cover;
  .apply-panel {
    margin: 0 auto;
    width: 1024px;
    text-align: center;
    .title {
      padding-top: 20px;
      padding-bottom: 0;
      font-family: PingFangSC-Medium;
      font-size: 28px;
    }
    .a-icon {
      font-size: 50px;
      color: white;
    }
    .btn-submit {
      width: 120px;
      background: #feae39;
      border-radius: 4px;
    }
    .ant-form-item {
      margin-bottom: 24px;
      .ant-input {
        width: 100%;
      }
    }
    // /deep/ .has-error .ant-form-explain,
    // /deep/ .has-error .ant-form-split {
    //   color: #feae39 !important;
    // }
  }
}
@media screen and (min-width: 320px) and (max-width: 1024px) {
 .apply-toast {
    width: 88%;
    margin-left:3%;
    padding: 32px;
    z-index:9999;
    background: #ffffff;
    border-radius: 15px;
    h3 {
      font-family: PingFangSC-Medium;
      font-size: 18px;
      color: #333333;
      font-weight: 500;
    }
    span {
      font-family: PingFangSC-Regular;
      font-size: 14px;
      color: #999999;
      font-weight: 400;
    }
  }
  .fixed-bar {
    display:none;
  }
}
@media screen and (min-width: 1024px){
  .apply-toast {
    width: 436px;
    padding: 32px;
    background: #ffffff;
    box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.12);
    box-shadow: 0px 6px 16px 0px rgba(0, 0, 0, 0.08);
    box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05);
    border-radius: 2px;
    z-index: 999;
    h3 {
      font-family: PingFangSC-Medium;
      font-size: 18px;
      color: #333333;
      font-weight: 500;
    }
    span {
      font-family: PingFangSC-Regular;
      font-size: 14px;
      color: #999999;
      font-weight: 400;
    }
  }
}

</style>